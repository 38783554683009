import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { Container } from "../../atoms/Container/Container";
import { Logo } from "../../atoms/Logo/Logo";
import { Button, ButtonSocial, Title } from "../../atoms";
/**
 * SignIn is the page where the user can sign in.
 */
export const SignIn = ({ onGoogleSignIn, onFacebookSignIn, onAppleSignIn, onEmailSignIn, }) => {
    return (_jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(Logo, { size: 'md' }), _jsx(ContentWrapper, { children: _jsx(StyledTitle, { children: "Welcome back" }) }), _jsxs(ButtonContainer, { children: [_jsxs(ButtonSocialContainer, { children: [onAppleSignIn && (_jsx(ButtonSocial, { variant: 'apple', ariaLabel: 'sign in with Apple', onPress: onAppleSignIn, isFullWidth: true })), onGoogleSignIn && (_jsx(ButtonSocial, { variant: 'google', ariaLabel: 'sign in with Google', onPress: onGoogleSignIn, isFullWidth: true })), onFacebookSignIn && (_jsx(ButtonSocial, { variant: 'facebook', ariaLabel: 'sign in with Facebook', onPress: onFacebookSignIn, isFullWidth: true })), _jsx(ButtonSocial, { ariaLabel: 'sign in', variant: 'email', onPress: onEmailSignIn, isFullWidth: true })] }), _jsx(Button, Object.assign({ variant: 'link', ariaLabel: 'register', isFullWidth: true, to: '/register' }, { children: "I don't have an account" }))] })] }) })));
};
const StyledContainer = styled(Container) `
  background: ${(props) => props.theme.color.neutral[20]};
  overflow-y: auto;
  height: 100%;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing[8]};
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.spacing[32]} 0;
`;
const StyledTitle = styled(Title) `
  text-align: center;
  font: ${(props) => props.theme.typography.heading['3xl']};
  margin: 0;

  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.heading['5xl']};
    `)}
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[48]};
  margin-bottom: ${(props) => props.theme.spacing[64]};
`;
const ButtonSocialContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[12]};
`;
SignIn.displayName = 'SignIn';
export default SignIn;
