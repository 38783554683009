import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Title } from "../../atoms";
import { BackButton } from "../../molecules";
import { Container } from "../../atoms/Container/Container";
import { Table } from "../../organisms/Table/Table";
import { Content } from "../../templates/Content/Content";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import { theme } from "../../../theme";
/**
 * TablePage is an editor for creating a new care profile.
 */
export const TablePage = ({ title, description, columns, rows, actions, toolbarActions, isTotalRows = true, rowsPerPage, }) => {
    // hooks
    const [width] = useWindowSize();
    return (_jsx(Content, { content: _jsxs(Container, Object.assign({ size: 'xl' }, { children: [_jsx(BackButton, {}), _jsxs(Wrapper, { children: [_jsxs("div", { children: [_jsx(Title, { children: title }), description && _jsx(Description, { children: description })] }), _jsx(Table, { columns: columns, rows: rows, actions: actions, toolbarActions: toolbarActions, isMobile: width < theme.breakpoint.mdValue, isTotalRows: isTotalRows, rowsPerPage: rowsPerPage })] })] })) }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
  width: 100%;
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
TablePage.displayName = 'TablePage';
export default TablePage;
