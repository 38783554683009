// components
import { Option } from '@/components/atoms/Select/Select';

interface Table {
  title: string;
  columns: string[];
  rows: string[];
}

/**
 * createFieldHelper asserts that the field definitions match the type of the
 * form data.
 */
export const createFieldHelper = <T>(fields: {
  [key in keyof T]?: FieldDef;
}) => {
  return fields;
};

/**
 * FieldType is the component type of a field.
 */
export enum FieldType {
  Text = 'text',
  TextArea = 'text-area',
  Password = 'password',
  Email = 'email',
  Number = 'number',
  Select = 'select',
  SelectCustom = 'select-custom',
  MultiSelect = 'multi-select',
  MultiSelectChip = 'multi-select-chip',
  MultiSelectBox = 'multi-select-box',
  MultiSelectCustom = 'multi-select-custom',
  RadioGroup = 'radio',
  Boolean = 'boolean',
  DatePicker = 'date',
  DateRange = 'date-range',
  Contact = 'contact',
  Address = 'address',
  Time = 'time',
  Slider = 'slider',
  SliderSelect = 'slider-select',
  SliderSeverity = 'slider-severity',
  BooleanTable = 'boolean-table',
  DateOfBirth = 'date-of-birth',
  QuestionTable = 'question-table',
  CareCircleMemberSelector = 'care-circle-member-selector',
  TimePicker = 'time-picker',
  ToggleSelect = 'toggle-select',
  Switch = 'switch',
}

/**
 * FieldDef is a definition of a field in a form.
 */
export type FieldDef = {
  id?: string;
  label: string;
  isRequired?: boolean;
  placeholder?: string;
  description?: string;
  tooltip?: string;
  order?: number;
  validation?: (value?: string | number | boolean) => boolean;
  isHideTitle?: boolean;
  isDisabled?: boolean;
  field: {
    type: FieldType;
    options?: Option[];
    table?: Table;
    size?: 'sm' | 'md' | 'lg';
    isCustom?: boolean;
    maxColumns?: number;
    subfields?: FieldDef[];
  };
  onInvite?: (careCircleMemberInvite: any) => Promise<any>;
};
