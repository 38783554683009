// fontawesome
import { library, } from '@fortawesome/fontawesome-svg-core';
// icons - find icons at https://fontawesome.com/search
import { faArrowCircleDown, faArrowCircleUp, faArrowDownToBracket, faArrowRightFromBracket, faArrowsRotate, faBadgeCheck, faBell, faBookmark, faBookOpen, faBuilding, faCalendarCheck, faCheck, faCheckCircle, faChevronDown, faChevronLeft, faChevronRight, faChevronsDown, faChevronsLeft, faChevronsRight, faChevronsUp, faChevronUp, faCircleHeart, faCirclePlus, faCircleQuestion, faCircleSterling, faClipboard, faClipboardCheck, faClipboardList, faClock, faCopy, faDiscDrive, faDownload, faEdit, faEnvelope, faEnvelopeOpenText, faExclamationCircle, faExclamationTriangle, faFileContract, faFilePlus, faFolderOpen, faGear, faHandHolding, faHandHoldingHeart, faHeadset, faHeart, faHeartPulse, faHome, faHorizontalRule, faHouse, faHouseBlank, faInfoCircle, faKitMedical, faList, faListCheck, faLocationDot, faLongArrowLeft, faLongArrowRight, faMagnifyingGlassLocation, faMailbox, faMoneyBills, faMoneyCheckPen, faPartyHorn, faPenField, faPhone, faPlus, faPlusSquare, faPrint, faQuestionCircle, faRotateRight, faRoute, faSearch, faSitemap, faSmile, faSpa, faStar, faSterlingSign, faTimes, faTrash, faTruckFast, faTv, faUpload, faUser, faUserGroup, faUsers, faVideo, faXmark, } from '@fortawesome/pro-light-svg-icons';
import { faBookmark as faBookmarkSolid, faCheck as faCheckSolid, faCircleXmark as faCircleXmarkSolid, faCheckCircle as faCheckCircleSolid, faChevronLeft as faChevronLeftSolid, faChevronRight as faChevronRightSolid, faPaperPlaneTop as faPaperPlaneTopSolid, faXmark as faXmarkSolid, } from '@fortawesome/pro-solid-svg-icons';
import { faApple, faFacebook, faGoogle, } from '@fortawesome/free-brands-svg-icons';
export const iconTypes = {
    faIcons: {
        'exclamation-triangle': faExclamationTriangle,
        'arrow-circle-down': faArrowCircleDown,
        'arrow-circle-up': faArrowCircleUp,
        'arrow-down-to-bracket': faArrowDownToBracket,
        'arrows-rotate': faArrowsRotate,
        'badge-check': faBadgeCheck,
        bell: faBell,
        bookmark: faBookmark,
        'book-open': faBookOpen,
        building: faBuilding,
        'circle-sterling': faCircleSterling,
        check: faCheck,
        'check-circle': faCheckCircle,
        'chevron-down': faChevronDown,
        'chevron-left': faChevronLeft,
        'chevron-right': faChevronRight,
        'chevron-up': faChevronUp,
        'chevrons-down': faChevronsDown,
        'chevrons-left': faChevronsLeft,
        'chevrons-right': faChevronsRight,
        'chevrons-up': faChevronsUp,
        clipboard: faClipboard,
        'clipboard-check': faClipboardCheck,
        'clipboard-list': faClipboardList,
        'circle-heart': faCircleHeart,
        'circle-plus': faCirclePlus,
        'circle-question': faCircleQuestion,
        copy: faCopy,
        'disc-drive': faDiscDrive,
        download: faDownload,
        edit: faEdit,
        'file-contract': faFileContract,
        'file-plus': faFilePlus,
        gear: faGear,
        'hand-holding': faHandHolding,
        'hand-holding-heart': faHandHoldingHeart,
        headset: faHeadset,
        heart: faHeart,
        'heart-pulse': faHeartPulse,
        home: faHome,
        house: faHouse,
        'house-blank': faHouseBlank,
        'info-circle': faInfoCircle,
        'kit-medical': faKitMedical,
        list: faList,
        'list-check': faListCheck,
        'long-arrow-left': faLongArrowLeft,
        'long-arrow-right': faLongArrowRight,
        'arrow-right-from-bracket': faArrowRightFromBracket,
        'magnifying-glass-location': faMagnifyingGlassLocation,
        'money-bills': faMoneyBills,
        'party-horn': faPartyHorn,
        phone: faPhone,
        plus: faPlus,
        'plus-square': faPlusSquare,
        print: faPrint,
        'rotate-right': faRotateRight,
        route: faRoute,
        search: faSearch,
        sitemap: faSitemap,
        smile: faSmile,
        spa: faSpa,
        star: faStar,
        'sterling-sign': faSterlingSign,
        times: faTimes,
        trash: faTrash,
        tv: faTv,
        upload: faUpload,
        user: faUser,
        users: faUsers,
        xmark: faXmark,
        'exclamation-circle': faExclamationCircle,
        'question-circle': faQuestionCircle,
        mailbox: faMailbox,
        envelope: faEnvelope,
        'envelope-open-text': faEnvelopeOpenText,
        'pen-field': faPenField,
        'truck-fast': faTruckFast,
        'money-check-pen': faMoneyCheckPen,
        'folder-open': faFolderOpen,
        'calendar-check': faCalendarCheck,
        'horizontal-rule': faHorizontalRule,
        clock: faClock,
        'location-dot': faLocationDot,
        video: faVideo,
        'user-group': faUserGroup,
    },
    faIconsSolid: {
        bookmark: faBookmarkSolid,
        check: faCheckSolid,
        'check-circle': faCheckCircleSolid,
        'circle-xmark': faCircleXmarkSolid,
        'chevron-left': faChevronLeftSolid,
        'chevron-right': faChevronRightSolid,
        'paper-plane-top': faPaperPlaneTopSolid,
        xmark: faXmarkSolid,
    },
    faIconsBrands: {
        apple: faApple,
        facebook: faFacebook,
        google: faGoogle,
    },
};
// retrieve a list of all libraries
const iconLibraries = Object.keys(iconTypes).map((iconTypeKey) => {
    return iconTypes[iconTypeKey];
});
// add to library and export types
iconLibraries.forEach((iconLibrary) => {
    library.add(...Object.values(iconLibrary));
});
/**
 * validateIcon ensures the provided icon string is of type Icons, and returns
 * the 'star' icon as a default if it is not.
 */
export const validateIcon = (icon) => {
    let isIcon = false;
    iconLibraries.forEach((iconLibrary) => {
        if (icon in iconLibrary) {
            isIcon = true;
        }
    });
    if (!isIcon)
        return 'star';
    return icon;
};
