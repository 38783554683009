var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// components
import { Editor } from "../../organisms/Editor/Editor";
// libs
import Markdown from 'react-markdown';
import { CardColor } from "../../atoms";
/**
 * CareProfileForm displays a single form from a care profile.
 */
export const CareProfileForm = ({ className, form, value, onChange, careRecipientName, isDisabled, }) => {
    // memos
    const formValue = useMemo(() => {
        if (!form || !value)
            return undefined;
        return value[form.id];
    }, [form, value]);
    // callbacks
    const placeholderReplacer = useCallback((input) => {
        return input === null || input === void 0 ? void 0 : input.replace('{careRecipient}', careRecipientName || 'the care recipient');
    }, [careRecipientName]);
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsxs(Header, { children: [_jsx(Title, { children: placeholderReplacer(form === null || form === void 0 ? void 0 : form.title) }), (form === null || form === void 0 ? void 0 : form.description) && (_jsx(Description, { children: _jsx(Markdown, Object.assign({ components: {
                                p: (_a) => {
                                    var { node } = _a, props = __rest(_a, ["node"]);
                                    return _jsx("div", Object.assign({}, props));
                                },
                                a: (_a) => {
                                    var { node } = _a, props = __rest(_a, ["node"]);
                                    return _jsx(StyledLink, Object.assign({}, props, { to: props.href }));
                                },
                            } }, { children: placeholderReplacer(form.description) })) }))] }), form && value && (_jsx(Editor, { fields: form.fields, isActionsDisabled: true, isFloatingActions: false, value: formValue, onChange: (val) => onChange === null || onChange === void 0 ? void 0 : onChange(Object.assign(Object.assign({}, value), { [form.id]: val })), isDisabled: !isDisabled })), _jsx(CardColor, Object.assign({ color: 'blue', isBordered: false }, { children: _jsxs(StyledLabel, { children: [_jsx("strong", { children: "Need Support? " }), "Ask any question using our AI chatbot, or schedule a call with your care expert."] }) }), 'card-content')] })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[32]};
  margin-top: ${(props) => props.theme.spacing[32]};
  padding-bottom: ${(props) => props.theme.spacing[32]};
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const StyledLink = styled(Link) `
  color: ${(props) => props.theme.color.primary[80]};
`;
const StyledLabel = styled.label `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
CareProfileForm.displayName = 'CareProfileForm';
export default CareProfileForm;
