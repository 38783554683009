// react
import { useCallback, useMemo } from 'react';

// components
import { RecipientProfileAttendanceAllowance } from '@karehero/llama';

// hooks
import {
  useCareCircle,
  useCareProfile,
  useCareProfileQuestions,
  MixpanelEvent,
  useMixpanel,
} from 'hooks';

/**
 * AttendanceAllowance shows the AttendanceAllowancePage component.
 */
const AttendanceAllowance = () => {
  // hooks
  const { currentCareCircle } = useCareCircle();
  const { numTotal, numCompleted, isFormStarted } = useCareProfile({
    categoryID: 'attendance-allowance',
  });
  const { track } = useMixpanel();

  // memos
  const careProfileQuestionsOptions = useMemo(
    () => ({
      questionIDs: [
        'care-recipient-location',
        'care-recipient-is-over-65',
        'care-recipient-health-condition',
        'care-recipient-previous-supervision',
        'care-recipient-terminally-ill',
        'care-recipient-help-needed',
      ],
    }),
    [],
  );
  const { createManyAnswer, questions, answers, setAnswers, answersRequest } =
    useCareProfileQuestions(careProfileQuestionsOptions);

  // callbacks
  const onEligibilityStatusChange = useCallback(
    (status: string) => {
      if (status !== 'incomplete') {
        track(MixpanelEvent.AttendanceAllowanceEligibilityResult, { status });
      }
    },
    [track],
  );

  const handleUpdateCareProfile = useCallback(async (): Promise<boolean> => {
    const res = await createManyAnswer(answersRequest);
    if (!('data' in res)) {
      console.error('Failed to update care profile', res.error);
      return false;
    }

    track(MixpanelEvent.AttendanceAllowanceEligibilitySubmitted, {});

    return true;
  }, [answersRequest, createManyAnswer, track]);

  return (
    <>
      <RecipientProfileAttendanceAllowance
        firstName={currentCareCircle?.careRecipientAccount?.firstName}
        limit={numTotal}
        progress={numCompleted}
        toAttendanceAllowance={
          '/attendance-allowance/attendance-allowance-general-information'
        }
        isFormStarted={isFormStarted}
        questions={questions}
        answers={answers}
        setAnswers={setAnswers}
        onEligibilityStatusChange={onEligibilityStatusChange}
        onUpdateCareProfile={handleUpdateCareProfile}
      />
    </>
  );
};

export default AttendanceAllowance;
