// react
import { useEffect } from 'react';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// components
import { Calendly as BookCallPage } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';
import { useGenerateCalendlyLinkMutation } from 'store/api/calendly';

const DEFAULT_CALENDLY_URL =
  'https://calendly.com/care-concierge-kh/care-concierge-initial-call';

/**
 * BookCall shows the BookCallPage component.
 */
const BookCall = () => {
  // hooks
  const account = useSelector(getAccount);
  const { track } = useMixpanel();

  const [generateCalendlyLink, { isLoading, data: calendlyLinkResponse }] =
    useGenerateCalendlyLinkMutation();

  // effects
  useEffect(() => {
    track(MixpanelEvent.BookCallViewed, {});
  }, [track]);

  useEffect(() => {
    generateCalendlyLink();
  }, [generateCalendlyLink]);

  return (
    <BookCallPage
      name={`${account.firstName || ''}`}
      kindeEmail={account.kindeEmail}
      baseURL={calendlyLinkResponse?.meetingUrl || DEFAULT_CALENDLY_URL}
      isLoading={isLoading}
    />
  );
};

export default BookCall;
