import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8085';
export const kindeEnvironmentUrl =
  process.env.REACT_APP_KINDE_ENVIRONMENT_URL || '';
export const kindeClientId = process.env.REACT_APP_KINDE_CLIENT_ID || '';
export const sanityProjectId = 'x7r1t5o8';
export const sanityDataset = 'production';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/v1/`,
    credentials: 'include',
  }),
  endpoints: () => ({}),
  tagTypes: [
    'Accounts',
    'AccountRoles',
    'ActionPlanCategory',
    'ActionPlan',
    'ActionPlanFeedback',
    'ArticleBookmarks',
    'CareCircles',
    'CareProfileCategories',
    'CareProfileQuestions',
    'CareProfileAnswers',
    'CarePlan',
    'Chat',
    'InvitedMembers',
    'OrganizationMembers',
    'Organizations',
    'Personalization',
    'Relationships',
    'Tiers',
  ],
});

export default api;
